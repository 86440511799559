// File: /src/pages/Contact.js

import React from "react";

const Contact = () => {
    return (
    <>
        <h1 id="contact_banner">Contact Us</h1>
        <form action="https://formsubmit.co/sjuhellenic@gmail.com" method="POST">      
            <input name="name" type="text" class="feedback-input" placeholder="Name" id="name" required/>
            <input name="email" type="text" class="feedback-input" placeholder="Email" id="email" required/>
            <input name="subject" type="text" class="feedback-input" placeholder="Subject" id="subject" required/>
            <textarea name="text" class="feedback-input" placeholder="Message" required></textarea>
            <input type="submit" value="SEND" onsubmit="sendMail()"/>
        </form>

        <style> {`
            #contact-form {
                margin-top: -5rem;
            }
            
            #contact_banner {
                color: #0073ff;
                max-width: 550px; 
                margin: 5rem auto; 
                margin-bottom: -1px;
                display: grid;
                place-content: center;
                font-weight: 800;
            }
            
            form { 
                max-width: 550px; 
                margin: 3rem auto; 
                display: grid;
                place-items: center;
                font-family: "Poppins", sans-serif;
            }
            
            .feedback-input {
                color: black;
                font-family: Helvetica, Arial, sans-serif;
                font-weight:500;
                font-size: 18px;
                border-radius: 5px;
                line-height: 22px;
                background-color: transparent;
                border:2px solid #0073ff;
                transition: all 0.3s;
                padding: 13px;
                margin-bottom: 15px;
                width:100%;
                box-sizing: border-box;
                outline:0;
            }
            
            .feedback-input:focus { 
                border:2px solid #0073ff; 
            }
          
            textarea {
                height: 150px;
                line-height: 150%;
                resize:vertical;
            }
            
            [type="submit"] {
                font-family: 'Montserrat', Arial, Helvetica, sans-serif;
                width: 100%;
                background:#0073ff;
                border-radius:5px;
                border:0;
                cursor:pointer;
                color:white;
                font-size:24px;
                padding-top:10px;
                padding-bottom:10px;
                transition: all 0.3s;
                margin-top:-4px;
                font-weight:700;
            }
            
            [type="submit"]:hover { 
                background:#0016d8; 
            }
            
            #icons {
                display: grid;
                place-content: center;
                margin-top: 2rem;
                font-size: 50px;
                color: inherit;
            }

        `}</style>
    </>
    );
}  // <--- Contact() function ends here

export default Contact;