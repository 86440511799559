// File: /src/components/EboardSection/index.js

import React from 'react'
import {PrevEboardTitle, PrevEboardContainer, PrevEboardCard, PrevEboardName, PrevEboardPosition, PrevEboardProfilePic } from './PrevEboardElements'
import { prevEboardMembers } from './data';

function addPrevEboardMember({name, position, posYear, img}) {
    return (
        <PrevEboardCard style={{marginRight: 100, marginTop: 50}}>
 
                <PrevEboardName>{name}</PrevEboardName>
                <PrevEboardPosition>{position} ({posYear})</PrevEboardPosition>
                <PrevEboardProfilePic src={img}/>  

        </PrevEboardCard>
    );
}; // <--- addPrevEboardMember() function ends here

const PrevEboardSection = () => {
    return (
        <>
            <PrevEboardTitle>Former Eboard</PrevEboardTitle>
            <PrevEboardContainer>
                {prevEboardMembers.map(addPrevEboardMember)}
            </PrevEboardContainer>
        </>
    );
}; // <--- PrevEboardSection() function ends here


export default PrevEboardSection;