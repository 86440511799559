// File: /src/components/EboardSection/index.js

import React from 'react'
import {EboardCard, EboardContainer, ProfilePic, Position, Name, Major, Minor, GradDate} from './EboardElements'

const EboardSection = ({name, position, major, minor, grad, img, linkedIn}) => {
    return (
        <>
            <EboardCard>
                <a href={linkedIn}>
                    <ProfilePic src={img}/>
                </a>
                <EboardContainer>
                    <Position>{position}</Position>
                    <Name><b>{name}</b></Name>
                    <Major>{major}</Major>
                    <Minor>{minor}</Minor>
                    <GradDate>{grad}</GradDate>
                </EboardContainer>
            </EboardCard>
        </>
    )
}

export default EboardSection;