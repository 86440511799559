// File: /src/components/Navbar/Navbar.js

import React, { useState } from "react";
import { FaBars, FaTimes, FaAngleUp, FaAngleDown } from "react-icons/fa";
import { DropdownOptions, DropdownOption, NavbarToggleIcon } from "./NavbarElements";

// import "./Navbar.css";
import logo from '../../img/sjuhellenic-logo.png'
import {
  NavbarWrapper,
  NavbarContainer,
  NavbarLogo,
  LogoImage,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuItemLink,
  NavbarMenuListLeft,
  NavbarMenuListRight,
  NavbarMobileMenu,
  NavbarMobileMenuList,
  NavbarMobileMenuItem,
  NavbarToggle,
  DropdownWrapper,
  DropdownText,
  MobileDropdownMenu,
  MobileDropdownOption
} from './NavbarElements'

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isEventsDropdownOpen, setIsEventsDropdownOpen] = useState(false);
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);

  const toggleEventsDropdown = () => {
    setIsEventsDropdownOpen(!isEventsDropdownOpen);
  };
  
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };
  

  return (
    <NavbarWrapper isMobileMenuOpen={isMobileMenuOpen}> 
      <NavbarContainer>
        <NavbarLogo href="/">
          <LogoImage src={logo} alt="logo" />
        </NavbarLogo>
        <NavbarMenu>
          <NavbarMenuListLeft>
            <NavbarMenuItem>
              <NavbarMenuItemLink href="/about">About</NavbarMenuItemLink>
            </NavbarMenuItem>
            <NavbarMenuItem>
              <NavbarMenuItemLink href="/eboard">Eboard</NavbarMenuItemLink>
            </NavbarMenuItem>
          </NavbarMenuListLeft>
          <NavbarMenuListRight>

          <NavbarMenuItem onClick={toggleEventsDropdown}>
            <DropdownWrapper>
              <DropdownText><NavbarMenuItemLink href="/events">Events</NavbarMenuItemLink></DropdownText>
              
              {isEventsDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}
            </DropdownWrapper>
          </NavbarMenuItem>
            {isEventsDropdownOpen && (
              <DropdownOptions>
                {/* Add your additional options here */}
                <DropdownOption><NavbarMenuItemLink href="/events/2023-2024">2023-2024</NavbarMenuItemLink></DropdownOption>
                <DropdownOption><NavbarMenuItemLink href="/events/2022-2023">2022-2023</NavbarMenuItemLink></DropdownOption>
                <DropdownOption><NavbarMenuItemLink href="/events/2021-2022">2021-2022</NavbarMenuItemLink></DropdownOption>
                <DropdownOption><NavbarMenuItemLink href="/events/2020-2021">2020-2021</NavbarMenuItemLink></DropdownOption>
                <DropdownOption><NavbarMenuItemLink href="/events/2019-2020">2019-2020</NavbarMenuItemLink></DropdownOption>
              </DropdownOptions>
            )}
    
            <NavbarMenuItem>
              <NavbarMenuItemLink href="/contact">Contact</NavbarMenuItemLink>
            </NavbarMenuItem>
          </NavbarMenuListRight>
        </NavbarMenu>
        <NavbarToggle onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
        </NavbarToggle>
      </NavbarContainer>
      <NavbarMobileMenu style={{opacity: isMobileMenuOpen ? 1 : 0, visibility: isMobileMenuOpen ? "visible" : "hidden", marginBottom:'100px'}} isMobileDropdownOpen={isMobileDropdownOpen}>
        <NavbarMobileMenuList>
          <NavbarMobileMenuItem>
            <NavbarMenuItemLink href="/about">About</NavbarMenuItemLink>
          </NavbarMobileMenuItem>
          <NavbarMobileMenuItem>
            <NavbarMenuItemLink href="/eboard">Eboard</NavbarMenuItemLink>
          </NavbarMobileMenuItem>

          <NavbarMobileMenuItem onClick={toggleMobileDropdown}>
          <span><NavbarMenuItemLink href="/events" style={{marginLeft:'10px'}}>Events</NavbarMenuItemLink>
          <span className="navbar-toggle-icon">{isMobileDropdownOpen ? <FaAngleUp /> : <FaAngleDown />}</span></span>



            {isMobileDropdownOpen && (
              <MobileDropdownMenu>
                {/* Add your additional options here */}
                <MobileDropdownOption><NavbarMenuItemLink href="/events/2023-2024">2023-2024</NavbarMenuItemLink></MobileDropdownOption>
                <MobileDropdownOption><NavbarMenuItemLink href="/events/2022-2023">2022-2023</NavbarMenuItemLink></MobileDropdownOption>
                <MobileDropdownOption><NavbarMenuItemLink href="/events/2021-2022">2021-2022</NavbarMenuItemLink></MobileDropdownOption>
                <MobileDropdownOption><NavbarMenuItemLink href="/events/2020-2021">2020-2021</NavbarMenuItemLink></MobileDropdownOption>
                <MobileDropdownOption><NavbarMenuItemLink href="/events/2019-2020">2019-2020</NavbarMenuItemLink></MobileDropdownOption>
              </MobileDropdownMenu>
            )}

          </NavbarMobileMenuItem>



          <NavbarMobileMenuItem>
            <NavbarMenuItemLink href="/contact">Contact</NavbarMenuItemLink>
          </NavbarMobileMenuItem>
        </NavbarMobileMenuList>
      </NavbarMobileMenu>
    </NavbarWrapper>
  );
} // <--- Navbar() function ends here

export default Navbar;