// File: /src/components/ImageCarousel/ImageCarousel.js
import React from "react";
// https://www.npmjs.com/package/react-responsive-carousel
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CarouselContainer, CarouselImg } from './ImageCarouselElements.js';

// Images: 
import Gala1 from '../../img/carouselPics/OSM.SJU1.jpg';
import Parade2022 from '../../img/carouselPics/Greek Parade 2022.jpeg';
import FrappeSale2019 from '../../img/carouselPics/Frappe Sale 2019.jpg'
import Homecoming2021 from '../../img/carouselPics/Homecoming 2021.jpg';
import Friendsgiving2021 from '../../img/carouselPics/Friendsgiving 2021.jpeg';
import Friendsgiving2022 from '../../img/carouselPics/Friendsgiving 2022.jpg';
import AMC2021 from '../../img/carouselPics/AMC 2021.jpg';
import MovieNightFall2021 from '../../img/carouselPics/Movie Night - Fall 2021.jpg';
import CarsKafe2022 from '../../img/carouselPics/Cars + Kafe 2022jpg.jpg';
import KaraokeNight from '../../img/carouselPics/Karaoke Night - 2019.jpg';

function ImageCarousel() {
    return (
        <CarouselContainer>
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}>
                <CarouselImg src={ Gala1 } alt="Hellenic Society Gala Pic 1" />
                <CarouselImg src={ FrappeSale2019 } alt="Frappe Sale 2019"/>
                <CarouselImg src={ Homecoming2021 } alt="Homecoming Weekend Parade 2021"/>
                <CarouselImg src={ AMC2021 } alt="Archangel Michael Church Festival - 2022" />
                <CarouselImg src={ MovieNightFall2021 } alt="Movie Night - Fall 2021" />
                <CarouselImg src={ Friendsgiving2021 } alt="Friendsgiving 2021"/>
                <CarouselImg src={ CarsKafe2022} alt="Cars + Kafe 2022" />
                <CarouselImg src={ Friendsgiving2022 } alt="Friendsgiving 2022"/>
                <CarouselImg src={ KaraokeNight } alt="Karaoke Night -2019" />
                <CarouselImg src={ Parade2022 } alt="Greek Independence Day Parade 2022" />
            </Carousel>
        </CarouselContainer>
    ); // <--- return() ends here
   
} // <--- ImageCarousel() ends here

export default ImageCarousel;