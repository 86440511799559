// File: /src/components/Footer/Footer.js

import React from "react";
import { FooterContainer, Social, SocialMediaIcon } from './FooterElements';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { SiGroupme, SiRedbubble } from "react-icons/si";

function Footer() {
    return(
    <>
        <FooterContainer>
            <Social>
                <SocialMediaIcon href="https://www.linkedin.com/company/st-john-s-university-hellenic-society">
                    <FontAwesomeIcon icon={faLinkedin} />
                </SocialMediaIcon>

                <SocialMediaIcon href="https://www.facebook.com/profile.php?id=100087106343889">
                    <FontAwesomeIcon icon={faFacebook} />
                </SocialMediaIcon>
                
                <SocialMediaIcon href="https://www.instagram.com/sjuhellenic">
                    <FontAwesomeIcon icon={faInstagram} />
                </SocialMediaIcon>

                <SocialMediaIcon href="https://groupme.com/join_group/50504374/eQSsD67R">
                    <SiGroupme />
                </SocialMediaIcon>

                <SocialMediaIcon href="http://www.redbubble.com/people/sjuhellenic">
                    <SiRedbubble />
                </SocialMediaIcon>

            </Social>
            <p style={{textAlign:'center', marginTop:'10px', marginBottom:'-10px'}}>
                © St. John's University Hellenic Society 2024
            </p>
        </FooterContainer>
  
    </>
    );
} // <--- Footer() function ends here

export default Footer;