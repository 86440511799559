// File: /src/components/EventsSection/index.js

import React from 'react';
import { EventContainer, EventDate, EventImage } from './EventElements';
import { events } from './data';
import { useLocation } from 'react-router-dom';

const EventsSection = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const year = pathname.split('/').pop();

  const filteredEvents = events.filter(event => {
    if (!year || year === 'events') {
      return true; // Include all events when no year is specified or '/events' route is accessed
    }
    return event.year === year;
  });

  return (
    <>
      {filteredEvents.map((event, index) => (
        <EventContainer key={index}>
          <EventDate>{event.date}</EventDate>
          <a href={event.igURL}>
            <EventImage src={event.img} alt={event.title} />
          </a>
        </EventContainer>
      ))}
    </>
  );
};

export default EventsSection;