// File: /src/pages/Home.jsx

import React from "react";

import YianniThunderbird from '../img/Yianni-Thunderbird.png'
import ImageCarousel from "../components/ImageSlider/ImageCarousel";

function Home() {
 
    return (
    <>
    
        <h1 class = "SJUHellenicBanner">St. John's University Hellenic Society</h1>
        
        <img id = "Thunderbird_left" src={YianniThunderbird} style={{opacity:"40%", width:"25%", float:'left', transform:'scaleX(-1)'}} />
        <img id = "Thunderbird_right" src={YianniThunderbird} style={{opacity:"40%", width:"25%", float:'right'}} />
        <h2 class = "tagline">Keeping the flame of the Greek diaspora alive in Queens, NY!</h2>

        
        <br/><br/>
        <h3 class = "quote">“Time is the wisest of all things that are; for it brings everything to light.”</h3>
        <h3 class = "philosopher"> - Thales of Miletus</h3>
        
        <br /><br />
        <br /><br />

        <ImageCarousel/>


        <br /><br /><br />

        {/* <iframe src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23039BE5&ctz=America%2FNew_York&showPrint=0&showNav=1&src=c2p1aGVsbGVuaWNAZ21haWwuY29t&color=%23039BE5" style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe> */}
        {/* <iframe
            src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23039BE5&ctz=America%2FNew_York&showPrint=0&showNav=1&src=c2p1aGVsbGVuaWNAZ21haWwuY29t&color=%23039BE5"
            title="St. John's University Hellenic Society Calendar"
            style={{ border: 'solid 1px #777', width: 800, height: 600 , display:'flex', marginLeft:'auto', marginRight:'auto'}}
            frameborder="0"
            scrolling="no"
        ></iframe> */}

        <style>{`
            .SJUHellenicBanner {
                display: block;
                text-align: center;
                margin-top: 40px;
                font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
                font-size: 60px;
                font-weight: bold;
                animation-delay: .175s;
            }

            .tagline {
                display: block;
                text-align: center;
                margin-top: 20px;
                margin-right: 0.5rem;
                font-family: Georgia, 'Times New Roman', Times, serif;
                font-size: 28px;
                animation-delay: .25s;
            }

            .quote, .philosopher {
                display: block;
                text-align: center;
                font-family: 'GFS Didot', serif;
                font-size: 1.3rem;
                animation-delay: .325s;
            }
            
            
            .SJUHellenicBanner, .tagline, .quote, .philosopher {
                animation: revealText .5s forwards;
            }

            @keyframes revealText {
                from {
                    transform: translateY(20px);
                }
                to {
                    opacity: 1;
                    transform: none;
                }
            }
        
        `}</style>
    </>
    );

} // <--- Home() function ends here

export default Home;