// File: /src/pages/Events.jsx

import React from 'react';
import EventsSection from '../components/EventsSection';

const Events = ({ year }) => {
  return (
    <>
      <EventsSection year={year} />
    </>
  );
};

export default Events;