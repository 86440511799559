// File: /src/components/EventsElements.js

import styled from "styled-components";

export const EventContainer = styled.div `
    display: grid;
    place-content: center;
    margin-top: 2%;
    animation: revealText .5s forwards;
    align-items: center;
    margin-bottom: 2rem;

    @media (max-width: 480px) {
      margin-top: 5%;
    }
`;

export const EventDate = styled.h1 `
    text-align: left;
    font-size: 1.7rem;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-decoration: none;
    text-decoration: underline;
    color: black;   
`;

export const EventImage = styled.img `
    width: 37.5rem;
    border-radius: 5px;
    margin-bottom: 10%;
    display: grid;
    justify-items: center;
    object-fit: cover;
    transition: transform .35s;

    &:hover {
        transform: scale(0.98);
    }

    @media (max-width: 480px) {
        width: 100%;
        margin-bottom: 5%;
    }
`;