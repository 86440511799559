// File: /src/pages/ErrorPage.jsx

import React from "react";
import WhereYouGoing from '../img/Error.png';

function ErrorPage() {

    return (
        <>
            <h1 style={{textAlign:'center', marginTop:'2rem', fontSize:'4rem'}}>This is not a valid page!</h1>
            <img src = {WhereYouGoing} style={{display:'block', marginLeft:'auto', marginRight:'auto'}} alt="Yianni Thunderbird"/>
        </>
    );

} // <--- ErrorPage() function ends here

export default ErrorPage;