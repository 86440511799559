// File: /src/components/EventsSection/data.js

// ----------- Image imports ----------- \\

// 2023 - 2024 pics
import Frappe_Cannoli_Sale_Spring_2024 from '../../img/eventPics/2023-2024/Frappe&CannoliSale-Spring2024.jpg'
import Marti_Bracelets_2024 from '../../img/eventPics/2023-2024/Marti_Bracelets_2024.jpg'
import Vasilopita_Cutting_2024 from '../../img/eventPics/2023-2024/Vasilopita_Cutting_2024.jpg'
import WinterParty_2023 from '../../img/eventPics/2023-2024/Winter Party_2023.jpg'
import Friendsgiving_2023 from '../../img/eventPics/2023-2024/Friendsgiving_2023.jpg'
import PaintNight2023 from '../../img/eventPics/2023-2024/PaintNight_2023.jpg'
import FrappeSaleFall2023 from '../../img/eventPics/2023-2024/FrappeSale-Fall_2023.jpg'
import Pumpkin_Painting_23 from '../../img/eventPics/2023-2024/Pumpkin_Painting_23.jpg'
import AMC_2023 from "../../img/eventPics/2023-2024/AMC_Festival_2023.jpg"
import Fall2023_GBM1 from "../../img/eventPics/2023-2024/Fall'23_GBM_1.jpg"
import Fall2023AF from '../../img/eventPics/2023-2024/Activity_Fair_Fall_2023.jpg'

// 2022 - 2023 pics
import Parade2023 from '../../img/eventPics/2022-2023/Parade_2023.jpg'
import YiannakiAwards2023 from '../../img/eventPics/2022-2023/Yiannaki_Awards_2023.jpg'
import CarsKafe2023 from '../../img/eventPics/2022-2023/Cars+Kafe-2023.jpg'
import Gala2023 from '../../img/eventPics/2022-2023/Gala_2023.jpg'
import MartiBracelet_2023 from '../../img/eventPics/2022-2023/Marti_Bracelet_2023.jpg'
import GBM1_Spring2023 from '../../img/eventPics/2022-2023/GBM1_Spring2023.jpg'
import Vasilopita2023 from '../../img/eventPics/2022-2023/Vasilopita2023.jpg'
import springActivityFair2023 from '../../img/eventPics/2022-2023/SpringActivityFair2023.jpg'
import friendsgiving2022 from '../../img/eventPics/2022-2023/Friendsgiving_2022.jpg'
import TieDye2022 from '../../img/eventPics/2022-2023/Fall_2022_TieDye.jpg'
import GBM2_Fall2022 from '../../img/eventPics/2022-2023/Fall_2022_2ndGBM.jpg'
import Fall2022_MovieNight from '../../img/eventPics/2022-2023/Fall2022-MovieNight.jpg'
import GBM1_Fall2022 from '../../img/eventPics/2022-2023/Fall_2022_1stGBM.jpg'
import Fall2022AF from '../../img/eventPics/2022-2023/Fall Activity Fair 2022.jpg'

// 2021 - 2022 pics
import Parade2022 from '../../img/eventPics/2021-2022/Parade_2022.jpg'
import Superlatives2022 from '../../img/eventPics/2021-2022/Superlatives_2022.jpg'
import SpringMovieNight2022 from '../../img/eventPics/2021-2022/MammaMia-banner.jpg'
import CarsKafe from '../../img/eventPics/2021-2022/Cars + Kafe.jpg'
import MartiBracelet_Sp2022 from '../../img/eventPics/2021-2022/Marti Bracelet-Spring 2022.jpg'
import Vasilopita2022 from '../../img/eventPics/2021-2022/Vasilopita_2022.jpg'
import Gingerbread_Parthenon from '../../img/eventPics/2021-2022/Gingerbread_Parthenon.jpg'
import Friendsgiving_2021 from '../../img/eventPics/2021-2022/Friendsgiving_2021.jpg'
import HCW_F21 from '../../img/eventPics/2021-2022/Homecoming_Weekend_Fall_2021.jpg'

// 2020 - 2021 pics
import AUxSJUKahoot from '../../img/eventPics/2020-2021/AUxSJU_KahootNight.jpg'

// 2019 - 2020 pics
import Frappe_Sale_2019 from '../../img/eventPics/2019-2020/Frappe_Sale_2019.jpg'
import SJUWorldCup_2019 from '../../img/eventPics/2019-2020/SJUWorldCup_2019.jpg'
import GBM1_Fall2019 from '../../img/eventPics/2019-2020/Fall2019_1stGBM.jpg'

// ----------- JSON data ----------- \\

export const events = [
     // ====== 2023 - 2024 ====== \\
     {
        date: '03/21/2024',
        title: 'Frappe & Cannoli Sale - Spring 2024',
        igURL: 'https://www.instagram.com/p/C4ygLp5uxLI/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
        img: Frappe_Cannoli_Sale_Spring_2024,
        year: '2023-2024',
     },
     {
        date: '02/22/2024',
        title: 'Marti Bracelet Making - 2024',
        igURL: 'https://www.instagram.com/p/C3rFOdiuF1G/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA==',
        img: Marti_Bracelets_2024,
        year: '2023-2024',
     },
     {
        date: '01/25/2024',
        title: 'Vasilopita Cutting - 2024',
        igURL: 'https://www.instagram.com/p/C2iaRkLu7wU/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==',
        img: Vasilopita_Cutting_2024,
        year: '2023-2024',
     },
     {
        date: '11/30/2023',
        title: 'Winter Party - 2023',
        igURL: 'https://www.instagram.com/p/C0SyH18O3dH/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==',
        img: WinterParty_2023,
        year: '2023-2024',
     },
     {
        date: '11/15/2023',
        title: 'Friendsgiving - 2023',
        igURL: 'https://www.instagram.com/p/CzwP9RiugFN/?utm_source=ig_web_copy_link&igsh=MjM0N2Q2NDBjYg==',
        img: Friendsgiving_2023,
        year: '2023-2024',
     },
     {
        date: '11/02/2023',
        title: 'Paint Night - 2023',
        igURL: 'https://www.instagram.com/p/CzKsz9GOBVw/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: PaintNight2023,
        year: '2023-2024',
     },
     {
        date: '10/19/2023',
        title: 'Frapple Sale - Fall 2023',
        igURL: 'https://www.instagram.com/p/CyrrhnGuKnh/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: FrappeSaleFall2023,
        year: '2023-2024',
     },
     {
        date: '10/05/2023',
        title: 'Pumpkin Painting - 2023',
        igURL: 'https://www.instagram.com/p/CyCHlKCO3xz/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: Pumpkin_Painting_23,
        year: '2023-2024',
     },
     {
        date: '09/22/2023',
        title: 'AMC Festival w/ SJU Hellenic - 2023',
        igURL: 'https://www.instagram.com/p/CxnYP_UOM_r/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: AMC_2023,
        year: '2023-2024',  
     },
     {
        date: '09/14/2023',
        title: 'Fall 2023 - General Body Meeting #1',
        igURL: 'https://www.instagram.com/p/CxNvUPCO1he/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: Fall2023_GBM1,
        year: '2023-2024',  
     },
     {
        date: '09/07/2023',
        title: 'Activity Fair - Fall 2023',
        igURL: 'https://www.instagram.com/p/Cw7pxanuZHn/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: Fall2023AF,
        year: '2023-2024',  
     },
     // ====== 2022 - 2023 ====== \\
     {
        date: '04/30/2023',
        title: 'Greek Independence Day Parade 2023',
        igURL: 'https://www.instagram.com/p/CrvkhyKu1gz/?utm_source=ig_web_copy_link',
        img: Parade2023,
        year: '2022-2023',  
     },
     {
        date: '04/24/2023',
        title: 'Yiannaki Awards 2023',
        igURL: 'https://www.instagram.com/p/Crjnp_CuIxg/?utm_source=ig_web_copy_link',
        img: YiannakiAwards2023,
        year: '2022-2023',  
     },
     {
        date: '04/22/2023',
        title: 'Cars + Kafe 2023',
        igURL: 'https://www.instagram.com/p/Crbf-C_ulbk/?utm_source=ig_web_copy_link',
        img: CarsKafe2023,
        year: '2022-2023',
     },
     {
        date: '03/24/2023',
        title: 'SJU Hellenic Gala - 2023',
        igURL: 'https://olgamukaphotography.client-gallery.com/gallery/sju-hellenic-society-gala',
        img: Gala2023,
        year: '2022-2023',
     },
     {
        date: '02/23/2023',
        title: 'Marti Bracelet Making - Spring 2023',
        igURL: 'https://www.instagram.com/p/CpBUReROysE/?utm_source=ig_web_copy_link',
        img: MartiBracelet_2023,
        year:'2022-2023',
     },
     {
        date: '02/13/2023',
        title: 'Spring GBM #1',
        igURL: 'https://www.instagram.com/p/Coqnz8fOyk2/?utm_source=ig_web_copy_link',
        img: GBM1_Spring2023,
        year:'2022-2023',
     },
     {
        date: '02/09/2023',
        title: 'Vasilopita Cutting 2023',
        igURL: 'https://www.instagram.com/p/CofJin4OHuz/?utm_source=ig_web_copy_link',
        img: Vasilopita2023,
        year:'2022-2023',
    },
    {
        date: '01/30/2023',
        title: 'Spring 2023 Activity Fair',
        igURL: 'https://www.instagram.com/p/CoI9hV3O3mS/?utm_source=ig_web_copy_link',
        img: springActivityFair2023,
        year:'2022-2023',
    },
    {
        date: '11/19/2022',
        title: '2022 Annual Friendsgiving',
        igURL: 'https://www.instagram.com/p/ClOzt-ouQsx/?utm_source=ig_web_copy_link',
        img: friendsgiving2022,
        year:'2022-2023',
    },
    {
        date: '10/27/2022',
        title: 'Tie Dye Fundraiser - Fall 2022',
        igURL: '',
        img: TieDye2022,
        year:'2022-2023',
    },
    {
        date: '10/03/2022',
        title: 'General Body Meeting #2 - Fall 2022',
        igURL: 'https://www.instagram.com/p/CjVog_dOu32/?utm_source=ig_web_copy_link',
        img: GBM2_Fall2022,
        year:'2022-2023',
    },
    {
        date: '09/22/2022',
        title: 'Fall Movie Night - 2022',
        igURL: 'https://www.instagram.com/p/Ci3jgzKuN5s/?utm_source=ig_web_copy_link',
        img: Fall2022_MovieNight,
        year: '2022-2023',
    },
    {
        date: '09/19/2022',
        title: 'General Body Meeting #1 - Fall 2022',
        igURL: 'https://www.instagram.com/p/CitQyIXO6Pw/?utm_source=ig_web_copy_link',
        img: GBM1_Fall2022,
        year: '2022-2023',
    },
    {
        date: '09/08/2022',
        title: 'Fall 2022 Activity Fair',
        igURL: 'https://www.instagram.com/p/CiVLU4xO5y_/?utm_source=ig_web_copy_link&igshid=MzRlODBiNWFlZA==',
        img: Fall2022AF,
        year: '2022-2023',
    },
    // ====== 2021 - 2022 ====== \\
    {
        date: '06/05/2022',
        title: 'Greek Independence Day Parade - 2022',
        igURL: 'https://www.instagram.com/p/Ceb8nf0O6wY/?utm_source=ig_web_copy_link',
        img: Parade2022,
        year:'2021-2022',
    },
    {
        date: '05/01/2022',
        title: 'SJU Hellenic Superlative Nominations 2022',
        igURL: 'https://www.instagram.com/p/CdBpNiIu3SE/?utm_source=ig_web_copy_link',
        img: Superlatives2022,
        year:'2021-2022'
    },
    {
        date: '04/05/2022',
        title: 'Spring Movie Night - 2022',
        igURL: 'https://www.instagram.com/p/CcD00jCOKUM/?utm_source=ig_web_copy_link',
        img: SpringMovieNight2022,
        year:'2021-2022'
    },
    {
        date: '03/12/2022',
        title: 'Cars + Kafe',
        igURL: 'https://www.instagram.com/p/CbA8ZmNuZU-/?utm_source=ig_web_copy_link',
        img: CarsKafe,
        year:'2021-2022'
    },
    {
        date: '02/24/2022',
        title: 'Marti Bracelet',
        igURL: 'https://www.instagram.com/s/aGlnaGxpZ2h0OjE3OTEzMzEzNjM0Mzc5MTY4?story_media_id=2778998765682206395_1593461679&igshid=YmMyMTA2M2Y=',
        img: MartiBracelet_Sp2022,
        year:'2021-2022'
    },
    {
        date: '01/20/2022',
        title: 'Cutting the Vasilopita - 2022',
        igURL: 'https://www.instagram.com/p/CY-lCIFML0M/?utm_source=ig_web_copy_link/',
        img: Vasilopita2022,
        year:'2021-2022'
    },
    {
        date: '12/02/2021',
        title: 'Gingerbread Parthenon Competition',
        igURL: 'https://www.instagram.com/p/CXFZdKOM5Ai/?utm_source=ig_web_copy_link',
        img: Gingerbread_Parthenon,
        year:'2021-2022'
    },
    {
        date: '11/20/2021',
        title: '2021 Annual Friendsgiving',
        igURL: 'https://www.instagram.com/p/CWi5obWFOp2/?utm_source=ig_web_copy_link',
        img: Friendsgiving_2021,
        year:'2021-2022'
    },
    {
        date: '10/24/2021',
        title: 'Homecoming Weekend - Fall 2022',
        igURL: 'https://www.instagram.com/p/CVbbPsKstFl/?utm_source=ig_web_copy_link',
        img: HCW_F21,
        year:'2021-2022'
    },
    // ====== 2020 - 2021 ====== \\
    {
        date: '03/25/2021',
        title: 'AU x SJU Kahoot Night',
        igURL: 'https://www.instagram.com/p/CM3RpoBlBMU/?utm_source=ig_web_copy_link',
        img: AUxSJUKahoot,
        year: '2020-2021',
    },
    // ====== 2019 - 2020 ====== \\
    {
        date: '11/21/2019',
        title: 'Frappe Sale 2019',
        igURL: 'https://www.instagram.com/p/Crbf-C_ulbk/?utm_source=ig_web_copy_link',
        img: Frappe_Sale_2019,
        year: '2019-2020',
    },
    {
        date: '10/21/2019',
        title: 'SJU World Cup - 2019',
        igURL: 'https://www.instagram.com/p/B35pr_qlJ9o/?utm_source=ig_web_copy_link',
        img: SJUWorldCup_2019,
        year: '2019-2020',
    },
    {
        date: '11/21/2019',
        title: 'General Body Meeting #1 - Fall 2019',
        igURL: 'https://www.instagram.com/p/B2y9QosnWk5/?utm_source=ig_web_copy_link',
        img: GBM1_Fall2019,
        year: '2019-2020',
    },
   
] // <--- events[] ends here