// File: /src/components/EboardSection/EboardElements.js

import styled from "styled-components";

export const EboardCard = styled.div `
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    width: 400px;
    margin: 0 90px;
    margin-top: 4rem;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    animation: revealCard .5s forwards;
    
    &:hover {
        transform: scale(0.99);
        box-shadow: 0 5px 15px rgba(0, 106, 255, 0.365);
    }

    @keyframes revealCard {
        from {
            transform: translateY(20px);
        }
        to {
            opacity: 1;
            transform: none;
        }
    }
`;

export const EboardContainer = styled.div `
    padding: 2px 16px;
    text-align: center;
    margin-top: 10px;
`;

export const ProfilePic = styled.img`
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 100%;
`;

export const Position = styled.h5`
    color: #c3964a; 
    font-weight: bold;
`;

export const Name = styled.h1`
    font-size: 23px;
`;

export const Major = styled.p `
    font-size: large;
`;

export const Minor = styled.p `
    font-size: large;
    margin-top: -1rem
`;

export const GradDate = styled.h5 `
    margin-top: 1.5rem;
`;