// File: /src/App.js

import React from 'react';
// import './App.css';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// Pages
import Home from './pages/Home';
import About from './pages/About';
import Eboard from './pages/Eboard';
import Events from './pages/Events';
import Contact from './pages/Contact';
import ErrorPage from './pages/ErrorPage';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/eboard" element={<Eboard />} />

        <Route path="/events" element={<Events />} />
        <Route path="/events/:year" element={<Events />} />

        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<ErrorPage/>} />

      </Routes>
      <Footer />
    </Router>
  );
} // <--- App() function ends here

export default App;