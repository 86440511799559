// File: /src/components/Footer/FooterElements.js

import styled from "styled-components";

export const FooterContainer = styled.div`
    padding: 30px 0;
    background: rgb(232, 251, 255);
    background: linear-gradient(
    90deg,
    rgba(232, 251, 255, 1) 0%,
    rgba(238, 252, 255, 1) 50%,
    rgba(232, 251, 255, 1) 100%
    );
    border: 2px groove #4287f5;
    border-left: none; 
    border-right: none;
    border-bottom: none;
    bottom: 0;
    width: 100%;
    margin-top: 100px;
`;

export const Social = styled.div`
    text-align: center;
    padding-bottom: 20px;
    color: #00bbff;
`;

export const SocialMediaIcon = styled.a`
    font-size: 40px;
    color: inherit;
    width: 40px;
    height: 40px;
    line-height: 50px;
    display: inline-block;
    text-align: center;
    margin: 0 30px;

    @media only screen and (max-device-width: 414px) {
        width: 15px;
    }
`;
