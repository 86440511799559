// File: /src/components/EboardSection/data.js

// Current eboard pics
import christinaPic from '../../img/eboardPics/Christina.png'
import sidPic from '../../img/eboardPics/Sid.jpeg'
import stavroPic from '../../img/eboardPics/Stavro.jpeg'
import mouzakitisPic from '../../img/eboardPics/Mouzakitis.png'
import ioannisPic from '../../img/eboardPics/Ioannis.jpeg'

// Former eboard pics
import annaPic from '../../img/eboardPics/Anna.jpg'
import mariaPic from '../../img/eboardPics/Maria.jpeg'
import demiPic from '../../img/eboardPics/Demi.jpg'
import adoniPic from '../../img/eboardPics/Adoni.jpeg'
import balanoPic from '../../img/eboardPics/Balanos.jpg'

import conPic from '../../img/eboardPics/Constantine.jpg'
import antigoniPic from '../../img/eboardPics/Antigoni.jpeg'

import stephPic from '../../img/eboardPics/Stephanie.jpg'
import georgePic from '../../img/eboardPics/George.jpg'
import kassimisPic from '../../img/eboardPics/Kassimis.jpg'
import alfonsoPic from '../../img/eboardPics/Alfonso.jpg'

import theoPic from '../../img/eboardPics/Theo.jpg'
import arianaPic from '../../img/eboardPics/Ariana.jpg'
import mikePic from '../../img/eboardPics/Mike.jpg'

// 2023 - 2024 E-Board 
export const Christina = {
    name: 'Christina Petrone',
    position: 'President',
    major: 'Speech Language Pathology & Audiology',
    grad: 'May 2025',
    linkedIn: '',
    img: christinaPic
};

export const Sid = {
    name: 'Isidoros “Sid” Doulis',
    position: 'Vice President',
    major: 'Health Administration & Human Services',
    grad: 'May 2024',
    linkedIn: 'https://www.linkedin.com/in/isidoros-doulis/',
    img: sidPic
};

export const Stavro = {
    name: 'Stavros Mastrogiannis',
    position: 'Treasurer',
    major: 'Finance',
    grad: 'May 2025',
    linkedIn: '',
    img: stavroPic
};

export const Mouzakitis = { 
    name: 'Maria Mouzakitis',
    position: 'Secretary',
    major: 'Childhood Education',
    linkedIn: '',
    grad: 'May 2025',
    img: mouzakitisPic
};

export const Ioannis ={
    name: 'Ioannis Bassiouris',
    position: 'Freshman Representative',
    major: 'Pharmacy',
    linkedIn: '',
    grad: 'May 2029',
    img: ioannisPic
};

export const prevEboardMembers = [
    { // Anna
        name: 'Anna Pierratos',
        position: 'President',
        posYear: '2021-2023',
        img: annaPic,
    },
    { // Maria
        name: 'Maria Triantafillou',
        position: 'Vice President',
        posYear: '2021 - 2023',
        img: mariaPic,
    },
    { // Demi
        name: 'Demetra "Demi" Koutmanis',
        position: 'Treasurer',
        posYear: '2022 - 2023',
        img: demiPic,
    },
    { // Adoni
        name: 'Antonios "Adoni" Takos',
        position: 'Secretary',
        posYear: '2022 - 2023',
        img: adoniPic,
    },
    {
        name: 'Anthony Balanos',
        position: 'Freshman Representative',
        posYear: '2022 - 2023',
        img: balanoPic,
    },
    { // Constantine
        name: 'Constantine Lascarides',
        position: 'Treasurer',
        posYear: '2021 - 2022',
        img: conPic,
    },
    { // Antigoni
        name: 'Antigoni Zoupa',
        position: 'Secretary',
        posYear: '2021 - 2022',
        img: antigoniPic,
    },
    { // Stephanie (President)
        name: 'Stephanie Papoutsakis',
        position: 'President',
        posYear: '2020 - 2021',
        img: stephPic,
    },
    { // George
        name: 'George Paleatsos',
        position: 'Vice President',
        posYear: '2020 - 2021',
        img: georgePic,
    },
    { // Diamantis Kassimis
        name: 'Diamantis Kassimis',
        position: 'Treasurer',
        posYear: '2020 - 2021',
        img: kassimisPic,
    },
    { // Nick Alfonso
        name: 'Nick Alfonso',
        position: 'Secretary',
        posYear: '2020 - 2021',
        img: alfonsoPic,
    },
    { // Theo
        name: 'Theodore Papoulis',
        position: 'President',
        posYear: '2019 - 2020',
        img: theoPic,
    },
    { // Ariana
        name: 'Ariana Nikolis',
        position: 'Vice President',
        posYear: '2019 - 2020',
        img: arianaPic,
    },
    { // Mike 
        name: 'Michael Mantikas',
        position: 'Treasurer',
        posYear: '2019 - 2020',
        img: mikePic,
    },
    { // Stephanie (Secretary)
        name: 'Stephanie Papoutsakis',
        position: 'Secretary',
        posYear: '2019 - 2020',
        img: stephPic,
    },
]; // <--- previousEboardMembers ends here


// Too sad to get hit delete :(

// export const Anna = {
//     name: 'Anna Pierratos',
//     position: 'President',
//     major: 'Government & Politics and Homeland Security',
//     minor:'Legal Studies minor',
//     grad: 'May 2023',
//     linkedIn: 'https://www.linkedin.com/in/anna-pierratos-4ab71b1b2?lipi=urn%3Ali%3Apage%3Acompanies_company_people_index%3B1020aec1-780a-4b72-9bfb-81bf99f2d1d0',
//     img: annaPic
// };

// export const Maria = {
//     name: 'Maria Triantafillou',
//     position: 'Vice President',
//     major: 'Biomedical Sciences',
//     minor:'Psychology minor',
//     grad: 'May 2023',
//     linkedIn: 'https://www.linkedin.com/in/maria-triantafillou-08b80a1a9?lipi=urn%3Ali%3Apage%3Acompanies_company_people_index%3B1020aec1-780a-4b72-9bfb-81bf99f2d1d0',  
//     img: mariaPic
// };

// export const Demi = {
//     name: 'Demetra "Demi" Koutmanis',
//     position: 'Treasurer',
//     major: 'Pharmacy',
//     grad: 'May 2027',
//     linkedIn: 'https://www.linkedin.com/in/demetra-koutmanis-089715202?lipi=urn%3Ali%3Apage%3Acompanies_company_people_index%3B1020aec1-780a-4b72-9bfb-81bf99f2d1d0',  
//     img: demiPic
// };

// export const Adoni = {
//     name: 'Antonios "Adoni" Takos',
//     position: 'Secretary',
//     major: 'Computer Science',
//     grad: 'May 2023',
//     linkedIn: 'https://www.linkedin.com/in/antonios-f-takos/',  
//     img: adoniPic
// };