// File: /src/components/EboardSection/PrevEboardEboardElements.js

import styled from "styled-components";

// export const PrevEboardCard = styled.div `
//     font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
//     animation: revealText .5s forwards;
//     width: 400px;
//     margin: 0 105px;
//     margin-top: 4rem;
// `;

export const PrevEboardCard = styled.div `
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    animation: revealText .5s forwards;
    width: 400px;
    margin: 0 105px;
    margin-top: 4rem;

    @media (max-width: 768px) {
        width: 100%;
        margin: 0 auto;
    }
`;

export const PrevEboardContainer = styled.div `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 50px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
    }
`;

export const PrevEboardTitle = styled.h1`
    display: grid;
    place-content: center;
    text-decoration: underline;
    font-weight: 700;
`;

export const PrevEboardName = styled.h2 `
    text-align: center;
`;

export const PrevEboardPosition = styled.h4 `
    text-align: center;  
`;

export const PrevEboardProfilePic = styled.img `
    width: 22rem;
    border-radius: 5px;
    margin-left: 6%;
`;