// File: /src/components/Navbar/NavbarElements.js

import styled from 'styled-components';

export const NavbarWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px; /* increased height for larger logo */
  background: rgb(0,181,255);
  background: linear-gradient(97deg, rgba(0,181,255,1) 0%, rgba(0,138,255,1) 30%, rgba(0,138,255,1) 70%, rgba(0,181,255,1) 100%);
  color: #fff;
  position: relative; /* added to contain the mobile menu */
  margin-bottom: ${props => props.isMobileMenuOpen ? '75%' : '0'}; /* added to push the content of the page down when the mobile menu is open */
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 0 20px;
`;

export const NavbarLogo = styled.a`
  position: absolute; /* added to center the logo */
  left: 50%;
  transform: translateX(-20%);
  font-size: 2rem;
`;

export const LogoImage = styled.img`
  width: 40%;
  border-radius: 15px;
  &:hover {
    transform: scale(0.95);
    color: rgba(255, 255, 255, 1);
    box-shadow: 0 4px 25px rgba(0, 217, 255, 0.744);
  }
`;

export const NavbarMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute; /* added to position the nav links */
  left: 50%;
  transform: translateX(-50%);
  z-index: 10; /* Add this line to increase the stacking order */
`;

export const NavbarMenuList = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
`;



export const NavbarMenuItem = styled.li`
  margin: 0 20px;
  font-size: 23px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    transform: scale(0.95);
  }
`;


export const NavbarMenuItemLink = styled.a`
  color: #fff;
  text-decoration: none;
  @media (max-width: 768px) {
    margin-right: 40px;
  }
`;

export const NavbarMenuListLeft = styled(NavbarMenuList)`
  position: absolute; /* added to position the right nav links */
  right: 0;
  transform: translateX(-100%);
`;

export const NavbarMenuListRight = styled(NavbarMenuList)`
  position: absolute; /* added to position the left nav links */
  left: 0;
  transform: translateX(100%);
`;

// export const NavbarMobileMenu = styled.ul`
//   display: none;
//   @media (max-width: 768px) {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     opacity: 0;
//     visibility: hidden;
//     position: absolute;
//     top: 120px; /* changed to accomodate the increased height */
//     left: 0;
//     width: 100%;
//     background: rgb(0,181,255);
//     background: linear-gradient(97deg, rgba(0,181,255,1) 0%, rgba(0,138,255,1) 30%, rgba(0,138,255,1) 70%, rgba(0,181,255,1) 100%);
//     transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
// `;


export const NavbarMobileMenu = styled.ul`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 160px; /* Adjust the top position based on the height of the navbar */
    left: 0;
    width: 100%;
    z-index: 10; /* Add this line to increase the stacking order */
    background: rgb(0, 181, 255);
    background: linear-gradient(
      97deg,
      rgba(0, 181, 255, 1) 0%,
      rgba(0, 138, 255, 1) 30%,
      rgba(0, 138, 255, 1) 70%,
      rgba(0, 181, 255, 1) 100%
    );
    transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;

    /* Add the necessary styles to the mobile dropdown menu */
    opacity: ${props => (props.isMobileDropdownOpen ? 1 : 0)};
    visibility: ${props => (props.isMobileDropdownOpen ? 'visible' : 'hidden')};
    margin-bottom: ${props => (props.isMobileDropdownOpen ? '100px' : '0')};
  }
`;


export const NavbarMobileMenuList = styled(NavbarMenuList)`
  flex-direction: column; 
  text-align: center;
  display: grid;
  justify-content: center; /* add this line to center the NavLink words */
`;

export const NavbarMobileMenuItem = styled(NavbarMenuItem)`
  margin: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    display: flex;
    align-items: center;
  }
`;



export const NavbarToggle = styled.button`
  display: none;

  @media screen and (max-width: 960px) {
    display: block;
    background: transparent;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    cursor: pointer;
    justify-content: flex-start;
    margin-bottom: 10px; // Move the toggle up by adding margin-top
  }
`;

export const NavbarToggleIcon = styled.span`
  margin-right: 500px;
`;


export const DropdownOptions = styled.div`
  position: absolute;
  top: 282%;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 10px;
  z-index: 999;
  background: rgb(0,181,255);
  background: linear-gradient(97deg, rgba(0,181,255,1) 0%, rgba(0,138,255,1) 30%, rgba(0,138,255,1) 70%, rgba(0,181,255,1) 100%);
`;

export const DropdownOption = styled.div`
  padding: 5px 10px;
  cursor: pointer;
  color: white;
  font-weight: bold;
  font-family: "Poppins",sans-serif;
  font-size: 20px;
  &:hover {
    background: #f5f5f5;
  }
`;

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const DropdownText = styled.span`
  margin-right: 5px;
`;

export const MobileDropdownMenu = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin-top: 10px; /* Add margin to create space between the "Events" item and the options */
`;


export const MobileDropdownOption = styled.li`
  margin: 10px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

















// export const NavbarMenuItem = styled.li`
//   margin: 0 20px;
//   font-size: 23px;
//   font-family: "Poppins",sans-serif;
//   font-weight: 600;
//   &:hover {
//     transform: scale(0.95);
//   }
// `;