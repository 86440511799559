// File: /src/pages/About.jsx

import React from "react";
// Images
import parade2022Pic from '../img/aboutPics/Greek Parade 2022.jpeg';
import frappe2019Pic from '../img/aboutPics/Frappe Sale 2019.jpg';
import homecomingPic from '../img/aboutPics/Homecoming 2021.jpg';

const About = () => {
  return (
    <>
      <h1 style={{textAlign:'center', marginTop:'20px', marginBottom:'20px', fontFamily:'Poppins, sans-serif', fontWeight:'700'}}>Who Are We?</h1>

      <img className="paradePic" src={parade2022Pic} alt="Greek Indepedence Day Parade 2022"/>

      <p className="intro">
        &ensp;The St. John’s University Hellenic Society is a student organization belonging to Campus Activities and officially recognized <br /> by Student Government, INC. 
        It was created as a language and culture organization at the original St. John’s University location <br /> in 1938. 
        85 years later, the organization thrives with 180+ registered members representing Greece, Cyprus, and the Hellenic <br /> Diaspora throughout the United States.
      </p>

      <p className="recognitions">
        &ensp;In recent years, we have gained much recognition among the campus community, the school’s alumni network, and the Greek <br /> community beyond school grounds. 
        In May 2022, our current President and Vice President, along with former board members were <br /> recognized by the New York State Assembly and the New York Attorney General for community involvement and student outreach. <br />
        The organization was recognized for our school spirit and Hellenic pride at the Homecoming Parade in 2021. In the same year, we <br /> raised over $1,000 to support the Ronald McDonald House – Greek Division’s Niki Sideris Annual Walk-A-Thon. 
        The Hellenic Society <br /> has also partaken in several other initiatives, including the promotion of professional development at National Hellenic Student <br /> Association and Philo4Thought events, where students received scholarships and awards at both.
      </p>

      <p className="conclusion">
        &ensp;We seek to grow our presence on campus and have a lasting impact on the school, students, and the supportive <br /> Greek- American community we serve.
      </p>

      <br /><br />
    
      <div className="bottom-photos">
        <img className="frappeSale" src={frappe2019Pic} alt="Frappe Sale 2019"/> <img className="homecoming" src={homecomingPic} alt="Homecoming Weekend 2021" />
      </div>
      
      <br /><br />

      <style>{`
        p {
          margin-left: 10px;
          margin-top: 10px;
          font-size: 20.5px;
        }

        .paradePic {
          width: 35%;
          float:right;
          margin-right: 5px;
          marginTop: -1.5rem;
          border-radius: 2px;
        }
        
        .frappeSale {
            width: 30%;
            border-radius: 2px;
        }

        .homecoming {
            width: 40%;
            border-radius: 2px;
        }

        .bottom-photos {
            display: flex;
            justify-content: center;
            gap: 10%;
        }

        /* Ultrawides */
        @media (min-width: 2000px) {

            p {
                max-width: 70%;
                margin-left: auto;
                margin-right: auto;
            }

            .paradePic {
                width: 25%;
                margin-right: 10rem;
            }
        }

        @media (max-width: 768px) {
            p {
              text-align: center;
              margin-right: 1rem;
            }
          
            .paradePic {
              display: block;
              margin: 0 auto;
              width: 100%;
              margin-bottom: 20px;
            }
          
            .bottom-photos {
              display: block;
              text-align: center;
            }
          
            .frappeSale,
            .homecoming {
              display: block;
              margin: 0 auto 1rem auto;
              width: 100%;
            }
          }
       
      `}</style>

    </>
  );
} // <--- About() function ends here

export default About;