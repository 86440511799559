// File: /src/pages/Eboard.jsx

import React from 'react';
import EboardSection from '../components/EboardSection';
import { Christina, Sid, Stavro, Mouzakitis, Ioannis } from '../components/EboardSection/data';
import { Container, Row, Col } from 'react-bootstrap';
import PrevEboardSection from '../components/EboardSection/PrevEboard';

const Eboard = () => {
  return (
  <>
    <Container>
      <Row className="d-none d-md-flex">
        <Col md={6}>
          <EboardSection {...Christina} />
        </Col>
        <Col md={6}>
          <EboardSection {...Sid} />
        </Col>
      </Row>
      <Row className="d-none d-md-flex">
        <Col md={6}>
          <EboardSection {...Stavro} />
        </Col>
        <Col md={6}>
          <EboardSection {...Mouzakitis} />
        </Col>
      </Row>
      <Row className="d-none d-md-flex">
        <Col style={{display:'grid', placeContent:'center'}}>
          <EboardSection {...Ioannis} />
        </Col>
      </Row>
{/* Mobile */}
    <div className="mobileEboard">
      <Row className="d-flex d-md-none">
        <Col>
          <EboardSection {...Christina} />
        </Col>
      </Row>
      <Row className="d-flex d-md-none">
        <Col>
          <EboardSection {...Sid} />
        </Col>
      </Row>
      <Row className="d-flex d-md-none">
        <Col>
          <EboardSection {...Stavro} />
        </Col>
      </Row>
      <Row className="d-flex d-md-none">
        <Col>
          <EboardSection {...Mouzakitis} />
        </Col>
      </Row>
      <Row className="d-flex d-md-none">
        <Col>
          <EboardSection {...Ioannis} />
        </Col>
      </Row>
    </div>
    </Container>
    <br /><br /><br />
    <hr />
    <br /><br /><br />
    <PrevEboardSection/>

    <style>{`


      /* Target iPhone devices */
      @media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2), 
      only screen and (max-device-width: 480px) and (min-resolution: 192dpi) {
        .mobileEboard {
          zoom: 80%;
          margin-left: -23.5%;
        }

      }

      /* Target Android devices */
      @media only screen and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 1.5), 
      only screen and (max-device-width: 480px) and (min-resolution: 240dpi) {
        .mobileEboard {
          zoom: 90%;
          display: grid;
          
        }
      }
      
    
    `}</style>
  </>
  ); // <--- return() ends here
}; // <--- Eboard() function ends here

export default Eboard;